<template>
	<TheHeader>
		<template v-slot:title>
			The <span class="line relative inline-block text-theme">Optiomat</span> ESOP glossary.
		</template>
	</TheHeader>

	<section class="page-container xl:max-w-screen-xl max-w-screen-md mt-10">

		<div data-aos="fade-up" class="bg-secondary flex items-center rounded-xl space-x-6 p-5 mb-5">
			<div>
				<img class="w-32" src="/images/icon-info.svg" alt="info">
			</div>
			<div class="w-full">
				<p class="font-bold italic leading-6">
					This Optiomat ESOP glossary consists of terms and definitions extracted from the Optiomat platform and their meaning is explained in relation to their use within the platform. The words and phrases included in this glossary are not intended to provide a full or complete list of an employee participation terminology.
				</p>
			</div>
		</div>

		<div class="md:flex items-start md:space-x-12 py-9 border-b border-secondary" v-for="item in glossary">
			<div class="md:w-3/12 md:text-right">
				<b class="font-extrabold mb-3 block text-lg">
					{{ item.title }}
				</b>
			</div>
			<div class="md:w-9/12">
				<p class="font-bold italic">
					{{ item.description }}
				</p>
			</div>
		</div>
	</section>

</template>

<script>
	import DescriptionCheck from "../components/DescriptionCheck";
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'ESOPGlossary',
		components: {
			DescriptionCheck,
			TheHeader,
		},
		data() {
			return {
				glossary: [
					{
						title: 'Accelerated vesting',
						description: 'an event in which all unvested options become vested before the end of agreed vesting period or milestone date; such event occurs when the company undergoes a liquidation, merger, division, sale of shares or listing of shares on the stock market after which the share of existing investors falls below the 50% share in the company',
					},
					{
						title: 'Bad leaver',
						description: 'a termination of the relationship based on an employment agreement or a service agreement between the employee and the company on the basis of circumstances that justify instant dismissal, in case of gross negligence by such employee in the performance of his duties, or if such employee becomes involved in any way with the business of a direct competitor of the company',
					},
					{
						title: 'Cliff grant',
						description: 'a part of target share for which employee earns options when cliff period runs out',
					},
					{
						title: 'Cliff period',
						description: 'the minimum period of time that must elapse before options begins to vest in respect of the time-based vesting',
					},
					{
						title: 'ESOP (Employee Share Option Plan)',
						description: 'a benefit plan in which employee owns options to acquire a percentage of company’s shares; note: the shares can be hold and administered by a separate organization, such as the  ESOP',
					},
					{
						title: 'ESOP Trust',
						description: 'a trust arrangement in which trustee holds option pool shares of the company for the benefit of ESOP participants',
					},
					{
						title: 'Exercise period',
						description: 'a period during which an employee can buy shares or share certificates the exercise price; note: options are only exercisable for a fixed period of time, until they expire',
					},
					{
						title: 'Exercise price',
						description: 'a fixed price per share or per share certificate at which company ownership can be purchased, as set in the ESOP Agreement; note: a price is generally set much lower than what can be expected will be the future value of the share, which means exercising options could be profitable',
					},
					{
						title: 'Good leaver',
						description: 'a termination of the relationship based on an employment agreement or a service agreement between the employee and the company for reasons other than “bad leaver”',
					},
					{
						title: 'Milestone date',
						description: 'a date scheduled to determine if a milestone set in the ESOP Agreement has been attained',
					},
					{
						title: 'Option',
						description: 'a right to buy the company\'s shares or share certificates at a specified price (exercise price) for a finite period of time (exercise period) under terms specified in the ESOP Agreement ',
					},
					{
						title: 'Option entitlement',
						description: 'a nominal value of shares per option specified in the ESOP agreement ',
					},
					{
						title: 'Option Pool',
						description: 'a block of company’s shares  that has been reserved for ESOPs',
					},
					{
						title: 'Share',
						description: 'one of the equal parts that the ownership of a company is divided into',
					},
					{
						title: 'Target share',
						description: 'a percentage ownership of the company that corresponds to the number of options granted by the ESOP Agreement; Note: a percentage amount is determined with regard to the capital structure of the company at the time of concluding the ESOP Agreement and can be diluted when the capital structure changes',
					},
					{
						title: 'Unvested options',
						description: 'options not yet fully earned under the terms of agreed vesting schedule',
					},
					{
						title: 'Vested options',
						description: 'options which employee has already earned; note: until all options granted under the ESOP Agreement are settled, all vested options are considered to be only partially vested, which means that the options are not yet exercisable and can be forfeited if the employee was to leave the company under “bad leaver” conditions',
					},
					{
						title: 'Vesting',
						description: 'a process based on a time or a milestone throughout which the employee earns the options granted by ESOP Agreement',
					},
					{
						title: 'Vesting period',
						description: 'a scheduled period for when employee normally earns options in full; note: scheduled period may be shortened if a good leaver or accelerated vesting event occurs',
					},
				]
			}
		}
	}
</script>
